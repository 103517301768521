import React from 'react'
import THEME from 'utils/theme'

const GlobalStyles = () => (
  <style jsx global>{`
    @font-face {
      font-family: 'Bebas Neue';
      font-display: block;
      src: url('/static/fonts/bebasneue_bold.woff') format('woff'),
        url('/static/fonts/bebasneue_bold.ttf') format('truetype'),
        url('/static/fonts/bebasneue_bold.eot') format('eot');
      font-weight: normal;
      font-style: normal;
    }
    * {
      box-sizing: border-box;
    }
    /* Resets */
    input,
    label,
    select,
    button,
    textarea {
      -webkit-appearance: none;
      margin: 0 0 12px;
      border: 0;
      padding: 0;
      display: block;
      vertical-align: middle;
      white-space: normal;
      background: none;
      line-height: 1;
      width: 100%;
    }
    input[type='checkbox'] {
      -webkit-appearance: checkbox;
      display: inline-block;
      width: inherit;
    }
    input[type='radio'] {
      -webkit-appearance: radio;
      display: inline-block;
      width: inherit;
    }
    body {
      margin: 0;
      font-family: ${THEME.FONTS.HELVETICA};
      -webkit-font-smoothing: antialiased;
      text-rendering: optimizeLegibility;
      color: ${THEME.COLORS.TEXTCOLOR};
      font-size: 16px;
    }
    body.drawer-open {
      overflow: hidden;
    }
    h1 {
      color: ${THEME.COLORS.TEXTCOLOR};
      font-size: 32px;
      font-weight: bold;
      letter-spacing: -0.1px;
      line-height: 40px;
    }
    p {
      font-size: 14px;
      letter-spacing: 0.1px;
      line-height: 20px;
      margin-top: 0;
      white-space: pre-line;
    }
    a {
      text-decoration: none;
    }
    img {
      max-width: 100%;
    }
    .responsiveEmbedContainer {
      position: relative;
      overflow: hidden;
      width: 100%;
      padding-top: 56.25%;
      height: 0;
    }
    .responsiveEmbedContainer iframe {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
    }
    .formGroup {
      margin-bottom: 15px;
      padding: 10px 15px;
      border: 2px solid #666;
      position: relative;
    }
    .formControl {
    }
    .formMessage {
      font-family: ${THEME.FONTS.HELVETICA};
      position: relative;
      text-align: center;
      margin: 1rem auto 1.5rem;
      padding: 0.875em;
      background: #f1f1f1;
      border: 1px solid ${THEME.COLORS.LIGHTERGRAY};
    }
    .formMessage__dismiss {
      position: absolute;
      top: 0.25rem;
      right: 0.25rem;
    }
    .formMessage__dismiss svg {
      height: 0.6rem;
    }
    .formMessage--success {
      background: #fff;
      border-color: #5aa700;
      color: #5aa700;
    }
    .formMessage--success svg {
      fill: #5aa700;
    }
    .formMessage--error {
      background: #fff;
      border-color: ${THEME.COLORS.ERROR};
      color: ${THEME.COLORS.ERROR};
    }
    .formMessage--error svg {
      fill: ${THEME.COLORS.ERROR};
    }
    .formMessage--small {
      padding-top: 1rem;
      font-size: 0.75rem;
    }
    .formSection .formGroup {
      border: 2px solid #666;
      box-sizing: border-box;
    }
    .formGroup label {
      display: inline-block;
      font-family: ${THEME.FONTS.HELVETICA};
      font-weight: 700;
      font-size: 0.625rem;
      padding: 5px 10px;
      color: ${THEME.COLORS.TEXTCOLOR};
      margin-bottom: 0;
    }
    .formGroup input {
      padding: 5px 10px;
      font-family: ${THEME.FONTS.HELVETICA};
      font-size: 16px;
      letter-spacing: 0;
      line-height: 16px;
      margin: 0;
      background: transparent;
      color: ${THEME.COLORS.TEXTCOLOR};
    }
    .stripeCardElement {
      padding: 5px 10px;
    }
    .formGroup select {
      position: relative;
      z-index: 1;
      max-width: 100%;
      width: 100%;
      padding: 5px 10px;
      margin: 0 !important;
      font-size: 1rem;
      background: transparent;
      display: inline-block;
      font-family: system-ui;
      border: 0;
      outline: none;
    }
    .formGroup input:focus,
    .formGroup textarea:focus,
    .formGroup select:focus {
      outline: none;
    }
    .finePrint {
      color: ${THEME.COLORS.GRAY};
      font-size: 10px;
      letter-spacing: 0.2px;
      line-height: 1rem;
    }
    .finePrint a,
    .finePrint a:hover {
      color: #367dc4 !important;
    }
    input,
    textarea,
    select {
      background-color: ${THEME.COLORS.LIGHTESTGRAY};
      font-size: 16px;
      padding: 13.5px 12px;
    }
    textarea {
      border: 1px solid ${THEME.COLORS.LIGHTGRAY};
      min-height: 85px;
    }
    select {
      height: 44px;
    }
    label {
      color: ${THEME.COLORS.TEXTCOLOR};
      font-size: 16px;
      font-weight: bold;
      line-height: 18px;
    }
    .form__error {
      background-color: #f8ece9;
      color: #d0341f;
      border: 1px solid #d0341f;
      text-align: center;
      font-size: 14px;
      letter-spacing: 0.1px;
      line-height: 20px;
      margin: 0 auto 12px;
      padding: 16px;
    }
    .loadingIcon {
      margin: auto;
    }
    .form__error--success {
      background-color: #eff8e9;
      color: #4c8c01;
      border-color: #5aa700;
      margin: 0 auto 12px;
    }
    .field__error {
      color: #d0341f;
      font-size: 10px;
      letter-spacing: 0.2px;
      line-height: 12px;
    }
    .formGroup > .field__error {
      padding: 3px 10px 0;
    }
    button[type='submit'],
    input[type='submit'],
    .ais-InfiniteHits-loadMore,
    .btn {
      font-size: 14px;
      text-transform: uppercase;
      font-weight: bold;
      font-family: ${THEME.FONTS.HELVETICA};
      color: #fff;
      background: ${THEME.COLORS.PRIMARY};
      letter-spacing: 0.4px;
      line-height: 20px;
      padding: 12px 40px;
      text-align: center;
      cursor: pointer;
      transition: all 0.15s ease-in-out;
    }
    .ais-InfiniteHits-loadMore--disabled {
      display: none;
    }
    .btn:disabled {
      cursor: not-allowed;
      background-color: #e8e8e8;
    }
    .btn:focus,
    .btn:hover,
    .btn--primary:focus,
    .btn--primary:hover {
      background-color: ${THEME.COLORS.PRIMARY_DARKENED};
    }
    .btn--secondary:hover,
    .btn--secondary:focus {
      background-color: transparent;
    }
    .btn--primary {
      font-size: 14px;
      text-transform: uppercase;
      font-weight: bold;
      color: #fff;
      background: ${THEME.COLORS.PRIMARY};
      letter-spacing: 0.4px;
      line-height: 20px;
      padding: 12px 40px;
      text-align: center;
      cursor: pointer;
    }
    .btn--secondary {
      border: 2px solid ${THEME.COLORS.SECONDARY};
      background-color: transparent;
      color: ${THEME.COLORS.SECONDARY};
      display: flex;
    }
    .btn--link {
      border: none;
      color: ${THEME.COLORS.TEXTCOLOR};
      background: none;
    }
    .btn--block {
      width: 100%;
    }
    .btn--link:hover,
    .btn--link:focus {
      background: none;
    }
    .btn {
      display: inline-block;
      outline: none;
    }
    button[type='submit']:disabled {
      background: #e8e8e8;
      cursor: not-allowed;
    }
    hr {
      border: 0;
      border-top: 1px solid ${THEME.COLORS.LIGHTGRAY};
    }
    .textCenter {
      text-align: center;
    }
    .container {
      max-width: 1200px;
      margin: 0 auto;
      padding: 0 15px;
    }
    .row {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-left: -15px;
      margin-right: -15px;
    }
    .col {
      flex: 1;
      padding-left: 15px;
      padding-right: 15px;
    }
    .row--noGutters {
      margin-left: 0;
      margin-right: 0;
    }
    .row--noGutters .col {
      padding-left: 0;
      padding-right: 0;
    }
    .row--scroll {
      flex-wrap: nowrap;
      overflow-x: auto;
    }
    .col--main {
      flex-basis: 100%;
      min-width: 100%;
    }
    .col--sidebar {
      flex-basis: 100%;
      min-width: 100%;
    }
    @media (min-width: ${THEME.BREAKPOINTS.SM}px) {
      .col--main {
        min-width: 55%;
        flex-basis: 55%;
      }
      .col--sidebar {
        min-width: 45%;
        flex-basis: 45%;
      }
    }
    @media (min-width: ${THEME.BREAKPOINTS.MD}px) {
      .col--main {
        min-width: 65%;
        flex-basis: 65%;
      }
      .col--sidebar {
        min-width: 35%;
        flex-basis: 35%;
      }
    }
    .container--form {
      max-width: 355px;
      padding-bottom: 100px;
    }
    /* react-select */
    .react-select__indicator-separator {
      display: none;
    }
    iframe {
      border: none;
    }
    @media (min-width: ${THEME.BREAKPOINTS.SM}px) {
      .featuredVideo .videoPlayerLink__image {
        margin-bottom: -3px;
      }
    }

    .bs-storyCardIndicator > .iconPlay {
      position: relative;
      left: 1.5px;
      margin: 0;
      vertical-align: middle;
    }

    .story__content span.image-attribution a,
    .story__content span.image-attribution {
      color: #757575;
      font-size: 12px;
      font-style: italic;
      letter-spacing: 0.1px;
      line-height: 14px;
      text-decoration: none;
    }
    .story__content span.image-attribution {
      display: none;
    }
    .story__content video ~ span.image-attribution {
      display: block;
      margin-top: -10px;
      margin-bottom: 15px;
    }
    .story__content img ~ span.image-attribution {
      display: block;
      margin-top: -10px;
      margin-bottom: 15px;
    }
    .story__content img {
      margin-bottom: 15px;
    }
    .story__content p a {
      color: #367dc4;
    }
    .story__content blockquote:not(.twitter-video) {
      display: block;
      width: 100%;
      margin: 8px 0 25px;
      padding: 16px 16px 16px 24px;
      border-left: 3px solid #77889a;
      font-style: italic;
      background: #f0f4f8;
      text-align: left;
    }
    .story__content blockquote:not(.twitter-video) p {
      font-style: italic;
      font-size: 16px;
      line-height: 1.4;
    }
    /* mux video */
    mux-player {
      aspect-ratio: 16 / 9;
    }
    /* Additonal Video Player in blog content */
    .story__content .videoPlayer {
      margin-bottom: 25px;
    }
    .videoPlayer,
    .bs-videoPlayer {
      position: relative;
      height: 100%;
      width: 100%;
    }
    .bs-videoPlayer__container video-js,
    .videoPlayer__container,
    .bs-videoPlayer__container {
      width: 100%;
      position: relative;
      height: 0;
      padding-top: 56.25%;
      overflow: hidden;
    }
    .bs-videoPlayer__container > iframe,
    .bs-videoPlayerContainer > video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    /* Progress Bar */
    #nprogress {
      pointer-events: none;
    }
    #nprogress .bar {
      background: ${THEME.COLORS.PRIMARY};
      position: fixed;
      z-index: 1031;
      top: 0;
      left: 0;
      width: 100%;
      height: 2px;
    }
    #nprogress .peg {
      display: block;
      position: absolute;
      right: 0px;
      width: 100px;
      height: 100%;
      box-shadow: 0 0 10px ${THEME.COLORS.PRIMARY}, 0 0 5px ${THEME.COLORS.PRIMARY};
      opacity: 1;
      -webkit-transform: rotate(3deg) translate(0px, -4px);
      -ms-transform: rotate(3deg) translate(0px, -4px);
      transform: rotate(3deg) translate(0px, -4px);
    }
    .ad--leaderboard,
    .ad--fixedBottom {
      text-align: center;
    }
    .videoList__ad [data-google-query-id] {
      padding-top: 12px;
      padding-bottom: 12px;
    }
    .siteNavigation__link {
      font-family: ${THEME.FONTS.BEBAS};
      font-size: 17.6px;
      font-weight: 300;
      text-transform: uppercase;
      letter-spacing: 0.4px;
      line-height: 30px;
      color: white;
      text-decoration: none;
      padding: 0 6.4px;
      white-space: nowrap;
      height: 100%;
      display: flex;
      align-items: center;
      position: relative;
    }
    .siteNavigation__dropdown a:before {
      background-repeat: no-repeat;
      position: absolute;
      left: 50%;
      right: auto;
      transform: translate(-50%);
    }
    .siteNavigation__activeLink {
      color: ${THEME.COLORS.PRIMARY};
    }
    .siteNavigation__link--primary {
      font-size: 16px;
    }
    @media (max-width: ${THEME.BREAKPOINTS.SM - 1}px) {
      .siteNavigation__link--mobile {
        font-size: 30px;
        margin-bottom: 25px;
        line-height: normal;
        color: white;
      }
    }
    .siteNavigation__link.siteNavigation__link--logout,
    .siteNavigation__link.siteNavigation__link--logout:hover,
    .siteNavigation__link.siteNavigation__link--logout:focus {
      color: ${THEME.COLORS.PRIMARY};
    }
    .siteNavigation__link--store::before {
      content: '';
      background-size: 1rem 0.5rem;
      background-image: url('/static/images/hat.png');
      width: 1rem;
      height: 0.5rem;
      top: 4px;
    }
    .siteNavigation__link:hover {
      color: #ffffff;
    }
    .siteNavigation__dropdown--active .dropdownMenu {
      display: block;
    }
    .siteNavigation__dropdown--active .siteNavigation__link {
      color: #ffffff;
    }
    .siteFooter__listItem a,
    .siteFooter__listItem a:visited {
      color: ${THEME.COLORS.TEXTCOLOR};
    }
    .sharingLink--sms {
      background-color: #33e223;
    }
    @media (min-width: ${THEME.BREAKPOINTS.SM}px) {
      .siteNavigation__link {
        padding: 0 0.8rem;
      }
      .siteNavigation__link--store:before {
        top: 16px;
      }
    }
    @media (min-width: ${THEME.BREAKPOINTS.MD}px) {
      .siteNavigation__link--primary {
        font-size: 18px;
      }
    }
    @media (min-width: ${THEME.BREAKPOINTS.LG + 1}px) {
      .siteSubNavigation ul li a {
        margin-right: 40px;
      }
      .siteNavigation__link--primary {
        font-size: 20px;
      }
    }
    .dropdownMenu {
      display: none;
    }
    .layout__overlay--active {
      display: block !important;
    }

    /**
     * Tooltip Styles
     */

    /* Base styles for the element that has a tooltip */
    [data-tooltip],
    .tooltip {
      position: relative;
      cursor: pointer;
    }

    /* Base styles for the entire tooltip */
    [data-tooltip]:before,
    [data-tooltip]:after,
    .tooltip:before,
    .tooltip:after {
      position: absolute;
      visibility: hidden;
      -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=0)';
      filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=0)';
      opacity: 0;
      -webkit-transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out,
        -webkit-transform 0.2s cubic-bezier(0.71, 1.7, 0.77, 1.24);
      -moz-transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out,
        -moz-transform 0.2s cubic-bezier(0.71, 1.7, 0.77, 1.24);
      transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out,
        transform 0.2s cubic-bezier(0.71, 1.7, 0.77, 1.24);
      -webkit-transform: translate3d(0, 0, 0);
      -moz-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      pointer-events: none;
    }

    /* Show the entire tooltip on hover and focus */
    [data-tooltip]:hover:before,
    [data-tooltip]:hover:after,
    [data-tooltip]:focus:before,
    [data-tooltip]:focus:after,
    .tooltip:hover:before,
    .tooltip:hover:after,
    .tooltip:focus:before,
    .tooltip:focus:after {
      visibility: visible;
      -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=100)';
      filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=100)';
      opacity: 1;
    }

    /* Base styles for the tooltip's directional arrow */
    .tooltip:before,
    [data-tooltip]:before {
      z-index: 1001;
      border: 6px solid transparent;
      background: transparent;
      content: '';
    }

    /* Base styles for the tooltip's content area */
    .tooltip:after,
    [data-tooltip]:after {
      z-index: 1000;
      padding: 8px;
      width: 160px;
      background-color: #000;
      background-color: hsla(0, 0%, 20%, 0.9);
      color: #fff;
      content: attr(data-tooltip);
      font-size: 14px;
      line-height: 1.2;
    }

    /* Directions */

    /* Top (default) */
    [data-tooltip]:before,
    [data-tooltip]:after,
    .tooltip:before,
    .tooltip:after,
    .tooltip-top:before,
    .tooltip-top:after {
      bottom: 100%;
      left: 50%;
    }

    [data-tooltip]:before,
    .tooltip:before,
    .tooltip-top:before {
      margin-left: -6px;
      margin-bottom: -12px;
      border-top-color: #000;
      border-top-color: hsla(0, 0%, 20%, 0.9);
    }

    /* Horizontally align top/bottom tooltips */
    [data-tooltip]:after,
    .tooltip:after,
    .tooltip-top:after {
      margin-left: -80px;
    }

    [data-tooltip]:hover:before,
    [data-tooltip]:hover:after,
    [data-tooltip]:focus:before,
    [data-tooltip]:focus:after,
    .tooltip:hover:before,
    .tooltip:hover:after,
    .tooltip:focus:before,
    .tooltip:focus:after,
    .tooltip-top:hover:before,
    .tooltip-top:hover:after,
    .tooltip-top:focus:before,
    .tooltip-top:focus:after {
      -webkit-transform: translateY(-12px);
      -moz-transform: translateY(-12px);
      transform: translateY(-12px);
    }

    /* Left */
    .tooltip-left:before,
    .tooltip-left:after {
      right: 100%;
      bottom: 50%;
      left: auto;
    }

    .tooltip-left:before {
      margin-left: 0;
      margin-right: -12px;
      margin-bottom: 0;
      border-top-color: transparent;
      border-left-color: #000;
      border-left-color: hsla(0, 0%, 20%, 0.9);
    }

    .tooltip-left:hover:before,
    .tooltip-left:hover:after,
    .tooltip-left:focus:before,
    .tooltip-left:focus:after {
      -webkit-transform: translateX(-12px);
      -moz-transform: translateX(-12px);
      transform: translateX(-12px);
    }

    /* Bottom */
    .tooltip-bottom:before,
    .tooltip-bottom:after {
      top: 100%;
      bottom: auto;
      left: 50%;
    }

    .tooltip-bottom:before {
      margin-top: -12px;
      margin-bottom: 0;
      border-top-color: transparent;
      border-bottom-color: #000;
      border-bottom-color: hsla(0, 0%, 20%, 0.9);
    }

    .tooltip-bottom:hover:before,
    .tooltip-bottom:hover:after,
    .tooltip-bottom:focus:before,
    .tooltip-bottom:focus:after {
      -webkit-transform: translateY(12px);
      -moz-transform: translateY(12px);
      transform: translateY(12px);
    }

    /* Right */
    .tooltip-right:before,
    .tooltip-right:after {
      bottom: 50%;
      left: 100%;
    }

    .tooltip-right:before {
      margin-bottom: 0;
      margin-left: -12px;
      border-top-color: transparent;
      border-right-color: #000;
      border-right-color: hsla(0, 0%, 20%, 0.9);
    }

    .tooltip-right:hover:before,
    .tooltip-right:hover:after,
    .tooltip-right:focus:before,
    .tooltip-right:focus:after {
      -webkit-transform: translateX(12px);
      -moz-transform: translateX(12px);
      transform: translateX(12px);
    }

    /* Move directional arrows down a bit for left/right tooltips */
    .tooltip-left:before,
    .tooltip-right:before {
      top: 3px;
    }

    /* Vertically center tooltip content for left/right tooltips */
    .tooltip-left:after,
    .tooltip-right:after {
      margin-left: 0;
      margin-bottom: -16px;
    }

    .staticPage {
      margin: 2rem auto 2rem;
      box-shadow: ${THEME.BOX_SHADOW.DEFAULT};
      background: #fff;
      border-top: none;
      display: block;
      color: ${THEME.COLORS.TEXTCOLOR};
      font-size: 100%;
      letter-spacing: normal;
    }

    .staticPage__content {
      padding: 1rem 2rem;
      display: block;
    }

    .staticPage__header {
      padding: 0;
    }

    .staticPage__content p {
      margin-bottom: 1.5rem;
      line-height: 1.5;
      letter-spacing: 0.1px;
      font-size: 100%;
    }

    .staticPage__content h1 {
      font-size: 1.875rem;
      padding: 1rem 0 0;
      margin: 0;
      line-height: 2;
      color: ${THEME.COLORS.TEXTCOLOR};
      font-weight: 600;
      display: block;
    }

    .staticPage__header h1 {
      line-height: 1.2;
      color: ${THEME.COLORS.TEXTCOLOR};
      font-weight: 600;
      display: block;
      font-size: 1.875rem;
      margin: 0;
      padding: 1rem 1.5rem 0;
    }

    .staticPage__content h2 {
      font-size: 1.125em;
      display: block;
      margin: 0 auto 0.5em;
      line-height: 120%;
      text-transform: uppercase;
      font-family: ${THEME.FONTS.HELVETICA};
      vertical-align: baseline;
      font-weight: 400;
      letter-spacing: 0.2px;
    }

    .staticPage__content h3 {
      font-family: ${THEME.FONTS.HELVETICA};
      font-weight: 600;
      margin: 0 auto 0.5em;
      line-height: 133%;
      vertical-align: baseline;
      box-sizing: border-box;
      font-size: 1em;
      display: block;
    }

    .staticPage__content ol {
      display: block;
      list-style-position: outside;
      margin: 0.5rem 0;
      padding-left: 40px;
      list-style-type: decimal;
      font-size: 100%;
      line-height: 1.5;
      vertical-align: baseline;
      margin-block-start: 1em;
      margin-block-end: 1em;
      margin-inline-start: 0px;
      margin-inline-end: 0px;
      padding-inline-start: 40px;
      color: ${THEME.COLORS.TEXTCOLOR};
      letter-spacing: normal;
    }

    .staticPage__content li {
      line-height: 1.5;
      letter-spacing: 0.1px;
      font-size: 100%;
      font: inherit;
      vertical-align: baseline;
      display: list-item;
      text-align: -webkit-match-parent;
    }

    .staticPage a,
    .staticPage a:hover {
      color: #367dc4;
      text-decoration: none;
      cursor: pointer;
    }

    .bs-articleAd {
      text-align: center;
      margin-bottom: 10px;
    }

    .freestarAd__container {
      background-color: #d8d8d8;
    }
    .freestarAd__unit--gallery .freestarAd__container,
    .ad--fixedBotom .freestarAd__container,
    .infinite-scroll-container .freestarAd__container,
    .videoList__ad .freestarAd__container {
      background: transparent;
    }
    .freestarAd__unit--gallery .freestarAd iframe,
    .ad--fixedBotom .freestarAd iframe,
    .infinite-scroll-container .freestarAd iframe,
    .videoList__ad .freestarAd iframe,
    .ad--leaderboard .freestarAd iframe {
      background-color: #d8d8d8;
    }

    /* GDPR */
    .qc-cmp-button,
    .qc-cmp-button.qc-cmp-secondary-button:hover {
      background-color: #c62232 !important;
      border-color: #c62232 !important;
    }
    .qc-cmp-button:hover,
    .qc-cmp-button.qc-cmp-secondary-button {
      background-color: transparent !important;
      border-color: #c62232 !important;
    }
    .qc-cmp-alt-action,
    .qc-cmp-link {
      color: #c62232 !important;
    }
    .qc-cmp-button,
    .qc-cmp-button.qc-cmp-secondary-button:hover {
      color: #ffffff !important;
    }
    .qc-cmp-button:hover,
    .qc-cmp-button.qc-cmp-secondary-button {
      color: #000000 !important;
    }
    .qc-cmp-small-toggle.qc-cmp-toggle-on,
    .qc-cmp-toggle.qc-cmp-toggle-on {
      background-color: ${THEME.COLORS.SECONDARY} !important;
      border-color: ${THEME.COLORS.SECONDARY} !important;
    }
    .qc-cmp-small-toggle,
    .qc-cmp-toggle {
      background-color: #c62232 !important;
      border-color: #c62232 !important;
    }
    a.qc-cmp-persistent-link {
      display: none !important;
      bottom: 60px !important;
    }

    .section .btn {
      display: table;
      margin: 0 auto;
      min-width: 300px;
    }
    .hero .panel__btn {
      display: block;
      margin: 15px auto 0;
      padding: 12px;
      background-color: ${THEME.COLORS.PRIMARY};
      color: #ffffff;
      font-family: ${THEME.FONTS.HELVETICA};
      font-size: 14px;
      font-weight: bold;
      letter-spacing: 1.78px;
      line-height: 20px;
      text-transform: uppercase;
      text-align: center;
    }
    .panel__btn:hover {
      background-color: ${THEME.COLORS.PRIMARY_DARKENED};
    }
    /* Slick Slider */
    .slick-list,
    .slick-slider,
    .slick-track {
      position: relative;
      display: block;
    }
    .slick-loading .slick-slide,
    .slick-loading .slick-track {
      visibility: hidden;
    }
    .slick-slider {
      box-sizing: border-box;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      -webkit-touch-callout: none;
      -khtml-user-select: none;
      -ms-touch-action: pan-y;
      touch-action: pan-y;
      -webkit-tap-highlight-color: transparent;
    }
    .slick-list {
      overflow: hidden;
      margin: 0;
      padding: 0;
    }
    .productCarousel .slick-list {
      padding-top: 16px;
    }
    .slick-list:focus {
      outline: 0;
    }
    .slick-list.dragging {
      cursor: pointer;
      cursor: hand;
    }
    .slick-slider .slick-list,
    .slick-slider .slick-track {
      -webkit-transform: translate3d(0, 0, 0);
      -moz-transform: translate3d(0, 0, 0);
      -ms-transform: translate3d(0, 0, 0);
      -o-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
    .slick-track {
      top: 0;
      left: 0;
    }
    .slick-track:after,
    .slick-track:before {
      display: table;
      content: '';
    }
    .slick-track:after {
      clear: both;
    }
    .slick-slide {
      display: none;
      float: left;
      height: 100%;
      min-height: 1px;
    }
    .slick-slide img {
      display: block;
    }
    .slick-slide.slick-loading img {
      display: none;
    }
    .slick-slide.dragging img {
      pointer-events: none;
    }
    .slick-initialized .slick-slide {
      display: block;
    }
    .slick-vertical .slick-slide {
      display: block;
      height: auto;
      border: 1px solid transparent;
    }
    .slick-arrow.slick-hidden {
      display: none;
    }
    .slick-dots,
    .slick-next,
    .slick-prev {
      position: absolute;
      display: block;
      padding: 0;
    }
    .slick-next,
    .slick-prev {
      font-size: 0;
      line-height: 0;
      top: 50%;
      -webkit-transform: translate(0, -50%);
      -ms-transform: translate(0, -50%);
      transform: translate(0, -50%);
      cursor: pointer;
      color: transparent;
      border: none;
      outline: 0;
      background: 0 0;
    }
    .slick-next:focus,
    .slick-next:hover,
    .slick-prev:focus,
    .slick-prev:hover {
      color: transparent;
      outline: 0;
      background: 0 0;
    }
    .slick-prev {
      left: -25px;
    }
    .slick-prev:before {
      content: none;
    }
    .slick-next {
      right: -25px;
    }
    .slick-dotted.slick-slider {
      margin-bottom: 30px;
    }
    .slick-dots {
      bottom: -25px;
      width: 100%;
      margin: 0;
      list-style: none;
      text-align: center;
    }
    .slick-dots li {
      position: relative;
      display: inline-block;
      width: 20px;
      height: 20px;
      margin: 0 5px;
      padding: 0;
      cursor: pointer;
    }
    .slick-dots li button {
      font-size: 0;
      line-height: 0;
      display: block;
      width: 20px;
      height: 20px;
      padding: 5px;
      cursor: pointer;
      color: transparent;
      border: 0;
      outline: 0;
      background: 0 0;
    }
    .slick-dots li button:focus,
    .slick-dots li button:hover {
      outline: 0;
    }
  `}</style>
)

export default GlobalStyles
