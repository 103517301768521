import React from 'react'

const AllyantLogo = () => {
  return (
    <svg
      role='img'
      className='allyant-logo'
      aria-label='Reviewed by Allyant for accessibility'
      width='80'
      height='80'
      viewBox='0 0 80 80'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <title>Reviewed by Allyant for accessibility</title>
      <g clip-path='url(#clip0_17462_1771)'>
        <path
          d='M39.9999 79.08C61.5832 79.08 79.0799 61.5833 79.0799 40C79.0799 18.4167 61.5832 0.919998 39.9999 0.919998C18.4166 0.919998 0.919922 18.4167 0.919922 40C0.919922 61.5833 18.4166 79.08 39.9999 79.08Z'
          fill='black'
        />
        <mask id='mask0_17462_1771' maskUnits='userSpaceOnUse' x='2' y='2' width='76' height='76'>
          <path
            d='M40 77.304C60.6025 77.304 77.304 60.6024 77.304 40C77.304 19.3976 60.6025 2.696 40 2.696C19.3976 2.696 2.69604 19.3976 2.69604 40C2.69604 60.6024 19.3976 77.304 40 77.304Z'
            fill='white'
          />
        </mask>
        <g mask='url(#mask0_17462_1771)'>
          <path
            d='M40.0001 79.16C61.6276 79.16 79.1601 61.6275 79.1601 40C79.1601 18.3725 61.6276 0.839996 40.0001 0.839996C18.3726 0.839996 0.840088 18.3725 0.840088 40C0.840088 61.6275 18.3726 79.16 40.0001 79.16Z'
            fill='white'
          />
        </g>
        <path
          d='M12.6585 22.8464L13.9177 21.128C14.4353 20.4216 15.1993 20.0592 15.9065 20.5768C16.3033 20.868 16.4353 21.2768 16.3337 21.76L16.3481 21.7704C16.6601 21.568 16.9601 21.564 17.2737 21.7048C17.7569 21.9176 18.1993 22.3608 18.3249 22.26L18.3785 22.2992L17.6481 23.296C17.5097 23.3432 17.1121 22.9928 16.7489 22.8376C16.4369 22.7056 16.2161 22.7224 15.9993 23.0176L15.6553 23.4872L16.8601 24.3704L16.1185 25.3816L12.6577 22.8456L12.6585 22.8464ZM14.9337 22.9592L15.3313 22.4168C15.5441 22.1264 15.5353 21.8744 15.2937 21.6968C15.0665 21.5304 14.8145 21.5688 14.6017 21.8592L14.1937 22.416L14.9337 22.9584V22.9592Z'
          fill='black'
        />
        <path
          d='M16.8008 17.72L19.2688 15.4664L19.9568 16.22L18.4144 17.628L18.8072 18.0576L20.0968 16.8808L20.732 17.5768L19.4424 18.7536L19.8952 19.2496L21.4816 17.8016L22.2056 18.5952L19.6928 20.8888L16.8 17.72H16.8008Z'
          fill='black'
        />
        <path
          d='M21.3601 13.8568L22.4641 13.1896L24.0105 14.6104C24.2921 14.868 24.6217 15.2152 24.6217 15.2152L24.6321 15.2088C24.6321 15.2088 24.4825 14.7528 24.3801 14.3864L23.8297 12.3632L24.9185 11.7048L25.9577 16.0904L24.7561 16.8168L21.3601 13.8568Z'
          fill='black'
        />
        <path d='M27.2568 10.6672L28.42 10.1984L30.024 14.1776L28.8608 14.6464L27.2568 10.6672Z' fill='black' />
        <path
          d='M31.093 9.272L34.349 8.5208L34.5786 9.5144L32.5442 9.984L32.6754 10.5512L34.377 10.1584L34.589 11.076L32.8874 11.4688L33.0386 12.1232L35.1314 11.64L35.373 12.6864L32.0578 13.4512L31.0938 9.2712L31.093 9.272Z'
          fill='black'
        />
        <path
          d='M37.065 8.0704H38.3186L38.6306 9.54C38.733 10.008 38.841 10.7336 38.841 10.7336H38.853C38.853 10.7336 38.9666 10.0912 39.0746 9.5992L39.4042 8.0696H40.5378L40.8682 9.5872C40.9762 10.0848 41.0842 10.7336 41.0842 10.7336H41.0962C41.0962 10.7336 41.2098 10.0072 41.3122 9.5272L41.6242 8.0688H42.9082L41.781 12.3584H40.5146L40.1306 10.5408C40.053 10.1688 39.9802 9.7072 39.9802 9.7072H39.9682C39.9682 9.7072 39.8962 10.1688 39.8186 10.5408L39.4466 12.3592H38.1626L37.0642 8.0696L37.065 8.0704Z'
          fill='black'
        />
        <path
          d='M45.6554 8.5256L48.9122 9.2752L48.6834 10.2688L46.649 9.8008L46.5186 10.368L48.2202 10.7592L48.009 11.6776L46.3074 11.2864L46.157 11.9416L48.2506 12.4232L48.0098 13.4696L44.6946 12.7072L45.6562 8.5264L45.6554 8.5256Z'
          fill='black'
        />
        <path
          d='M51.7042 10.1896L53.5074 11.0072C54.633 11.5176 55.0874 12.5936 54.5242 13.8336C53.9594 15.0792 52.8986 15.4416 51.6858 14.8912L49.9314 14.096L51.7034 10.1888L51.7042 10.1896ZM52.0218 13.9048C52.5466 14.1424 53.0658 13.9704 53.3658 13.3088C53.6586 12.664 53.4826 12.136 52.9362 11.8888L52.417 11.6536L51.5026 13.6704L52.0218 13.9056V13.9048Z'
          fill='black'
        />
        <path
          d='M60.2778 15.088L61.9378 16.5624C62.2514 16.8416 62.417 17.1248 62.4722 17.4064C62.5338 17.7256 62.4506 18.0456 62.2154 18.3096C61.9442 18.6144 61.6426 18.692 61.2706 18.5696L61.2586 18.5832C61.509 19.0064 61.4474 19.4816 61.125 19.8448C60.8178 20.1904 60.4482 20.3272 60.0754 20.2848C59.7626 20.248 59.4522 20.092 59.1202 19.7976L57.429 18.2952L60.2778 15.088ZM59.6706 18.9704C59.8722 19.1496 60.1194 19.152 60.2906 18.9592C60.4738 18.7528 60.413 18.5144 60.2202 18.3424L59.5562 17.7528L59.0026 18.376L59.6714 18.9696L59.6706 18.9704ZM60.673 17.6704C60.8522 17.8296 61.049 17.852 61.201 17.6816C61.3482 17.516 61.3106 17.3056 61.1314 17.1456L60.553 16.6312L60.0906 17.152L60.6738 17.6696L60.673 17.6704Z'
          fill='black'
        />
        <path
          d='M63.3417 22.1312L64.6729 19.2784L65.4953 20.364L65.0105 21.304C64.8465 21.624 64.6345 21.9504 64.6345 21.9504L64.6417 21.96C64.6417 21.96 65.0169 21.8488 65.3689 21.7776L66.4049 21.5648L67.2089 22.6264L64.0985 23.1312L62.8977 24.0408L62.1401 23.0408L63.3409 22.1312H63.3417Z'
          fill='black'
        />
        <path
          d='M49.4801 34.7368C49.1089 34.4272 48.6481 34.1888 48.0993 34.0232C47.5505 33.8576 46.9257 33.7728 46.2249 33.7704C45.5561 33.768 44.9473 33.8392 44.3969 33.9848C43.8465 34.1304 43.3689 34.3424 42.9633 34.62C42.5577 34.8984 42.2329 35.2344 41.9905 35.6288C41.7481 36.0232 41.6033 36.4648 41.5561 36.9528L44.1401 36.9624C44.2569 36.6416 44.4793 36.3984 44.8081 36.232C45.1369 36.0664 45.5545 35.984 46.0625 35.9864C46.6473 35.9888 47.0921 36.1112 47.3961 36.3528C47.7001 36.5944 47.8513 36.9376 47.8497 37.3816L47.8465 38.2496L45.2721 38.3936C43.9409 38.4656 42.9217 38.772 42.2129 39.312C41.5033 39.8528 41.1473 40.608 41.1433 41.5784C41.1417 42.0672 41.2249 42.5072 41.3937 42.9C41.5625 43.2928 41.8001 43.6296 42.1081 43.9104C42.4153 44.1912 42.7809 44.408 43.2049 44.5608C43.6281 44.7136 44.0969 44.7912 44.6113 44.7928C45.3049 44.7952 45.9241 44.6608 46.4689 44.3904C47.0129 44.1192 47.4241 43.74 47.7025 43.2528H47.8761L47.8705 44.6416L50.5889 44.652L50.6169 37.3928C50.6193 36.8272 50.5225 36.3208 50.3289 35.8736C50.1345 35.4264 49.8513 35.048 49.4801 34.7376M47.8369 40.7856C47.8361 41.0552 47.7777 41.3056 47.6609 41.5368C47.5441 41.768 47.3833 41.9696 47.1769 42.1424C46.9705 42.3152 46.7289 42.4488 46.4521 42.544C46.1753 42.6392 45.8737 42.6864 45.5457 42.6856C45.0569 42.684 44.6593 42.5696 44.3521 42.3432C44.0441 42.1176 43.8913 41.8048 43.8929 41.4064C43.8961 40.6352 44.4953 40.2104 45.6913 40.1312L47.8417 40.0048L47.8385 40.7856H47.8369Z'
          fill='black'
        />
        <path
          d='M58.6329 33.8176C57.8425 33.8144 57.1818 33.984 56.6498 34.3256C56.1186 34.668 55.729 35.14 55.4826 35.7432H55.309L55.3154 34.036L52.597 34.0256L52.5562 44.6584L55.3618 44.6688L55.385 38.5376C55.3866 38.1904 55.4361 37.8744 55.5329 37.5888C55.6305 37.3032 55.7706 37.0592 55.9554 36.8576C56.1394 36.656 56.3602 36.5008 56.617 36.3928C56.8746 36.2848 57.1674 36.2312 57.4946 36.2328C58.1634 36.2352 58.6554 36.4256 58.9722 36.8024C59.289 37.1792 59.4458 37.7376 59.4426 38.4768L59.4185 44.6848L62.2241 44.6952L62.2506 37.8216C62.2554 36.5488 61.949 35.5648 61.3314 34.868C60.7138 34.1712 59.8138 33.8208 58.6314 33.8168'
          fill='black'
        />
        <path
          d='M67.0849 33.4824L67.0921 31.556L64.2865 31.5456L64.2473 41.832C64.2457 42.3528 64.3097 42.796 64.4401 43.1632C64.5705 43.5296 64.7801 43.8312 65.0681 44.0672C65.3561 44.3032 65.7289 44.476 66.1849 44.5872C66.6409 44.6984 67.1929 44.7552 67.8425 44.7576C68.1129 44.7584 68.3713 44.7464 68.6185 44.7216C68.8657 44.6968 69.0769 44.6688 69.2505 44.6376L69.2585 42.4976C69.1233 42.5104 68.9977 42.5192 68.8825 42.5248C68.7665 42.5312 68.6153 42.5336 68.4297 42.5328C67.9481 42.5312 67.5977 42.4208 67.3801 42.2008C67.1625 41.9816 67.0545 41.6408 67.0561 41.1776L67.0745 36.396L69.2825 36.4048L69.2913 34.1968L67.7889 34.1912C67.3993 34.1896 67.0849 33.8728 67.0865 33.4832'
          fill='black'
        />
        <path
          d='M35.8336 42.0208H35.66L33.4544 33.952H33.2792L32.0584 37.9488C31.9616 38.2664 31.9672 38.6072 32.0752 38.9216L34.0496 44.6552L33.952 45.04C33.8344 45.4448 33.6232 45.736 33.3168 45.9144C33.0112 46.0928 32.5944 46.1816 32.0672 46.18H31.9328L31.7448 46.1784C31.6768 46.1784 31.6144 46.176 31.5568 46.1728C31.4992 46.1688 31.4568 46.164 31.4312 46.1576L31.4224 48.4136C31.448 48.42 31.5144 48.4256 31.62 48.4288C31.7256 48.432 31.84 48.436 31.9624 48.44C32.0848 48.444 32.1968 48.4456 32.3 48.4456H32.4928C33.1544 48.4488 33.7384 48.3864 34.2432 48.26C34.748 48.1336 35.1944 47.9248 35.5808 47.6336C35.968 47.3424 36.3048 46.9632 36.5928 46.4952C36.8808 46.0272 37.1384 45.4496 37.3664 44.7632L41.0712 33.98L38.1024 33.9688L35.8352 42.0192L35.8336 42.0208Z'
          fill='black'
        />
        <path
          d='M27.7136 27.14L24.6944 27.1288L19.8928 42.9528H19.7552L19.7768 37.2736C19.7792 36.708 19.6824 36.2016 19.4888 35.7544C19.2944 35.3072 19.0112 34.9288 18.64 34.6184C18.268 34.3088 17.808 34.0704 17.2592 33.9048C16.7104 33.7392 16.0856 33.6544 15.3848 33.652C14.716 33.6496 14.1072 33.7208 13.5568 33.8664C13.0064 34.012 12.5288 34.224 12.1224 34.5016C11.7168 34.78 11.392 35.116 11.1496 35.5104C10.9072 35.9048 10.7624 36.3464 10.7152 36.8344L13.2992 36.844C13.416 36.5232 13.6384 36.28 13.9672 36.1136C14.296 35.948 14.7136 35.8656 15.2216 35.868C15.8064 35.8704 16.2512 35.9928 16.5552 36.2344C16.8592 36.476 17.0104 36.8192 17.0088 37.2632L17.0056 38.1312L14.4312 38.2752C13.1008 38.3472 12.0808 38.6536 11.372 39.1936C10.6632 39.7344 10.3064 40.4896 10.3032 41.46C10.3016 41.9488 10.3848 42.3888 10.5536 42.7816C10.7224 43.1744 10.96 43.5112 11.268 43.792C11.576 44.0728 11.9408 44.2896 12.3648 44.4424C12.788 44.5952 13.2568 44.6728 13.7712 44.6744C14.4656 44.6768 15.084 44.5424 15.6288 44.272C16.1728 44.0008 16.584 43.6216 16.8624 43.1344H17.036L17.0304 44.5232L19.7488 44.5336L22.432 44.544L27.7128 27.1408L27.7136 27.14ZM16.9976 40.6656C16.9968 40.9352 16.9376 41.1856 16.8216 41.4168C16.7048 41.648 16.5432 41.8496 16.3376 42.0224C16.1312 42.1952 15.8896 42.3288 15.6128 42.424C15.336 42.5192 15.0344 42.5664 14.7064 42.5656C14.2176 42.564 13.82 42.4496 13.5128 42.2232C13.2048 41.9976 13.052 41.6848 13.0536 41.2864C13.0568 40.5152 13.656 40.0904 14.8512 40.0112L17.0016 39.8848L16.9984 40.6656H16.9976Z'
          fill='black'
        />
        <path d='M30.3816 27.1504L25.1008 44.5536L28.12 44.5648L33.4 27.1624L30.3816 27.1504Z' fill='black' />
        <path
          d='M14.3279 49.9512L15.6999 52.9592L14.7391 53.3976L13.8879 51.5304L13.2487 51.8216L13.9535 53.3664L13.0695 53.7696L12.3647 52.2248L10.9455 52.872L10.4255 51.7312L14.3287 49.9512H14.3279Z'
          fill='black'
        />
        <path
          d='M14.1711 54.7104C15.2367 53.9944 16.4975 54.16 17.2399 55.2656C17.9823 56.3712 17.6655 57.6104 16.5991 58.3264C15.5335 59.0424 14.2711 58.8632 13.5287 57.7576C12.7863 56.652 13.1047 55.4256 14.1711 54.7104ZM15.8903 57.2704C16.4735 56.8792 16.7455 56.3272 16.4111 55.8296C16.0767 55.3312 15.4631 55.3752 14.8799 55.7664C14.2967 56.1576 14.0295 56.7064 14.3639 57.204C14.6983 57.7024 15.3071 57.6616 15.8903 57.2704Z'
          fill='black'
        />
        <path
          d='M18.9486 57.7616L20.4206 59.3016C21.0262 59.9352 21.2502 60.7504 20.6166 61.356C20.2614 61.696 19.835 61.7544 19.3766 61.5704L19.3638 61.5832C19.5086 61.9264 19.4598 62.2216 19.267 62.5064C18.9726 62.9456 18.4598 63.3032 18.5374 63.4448L18.4902 63.4904L17.6358 62.5968C17.6134 62.452 18.0286 62.1216 18.2438 61.7912C18.4286 61.5064 18.4502 61.2864 18.1974 61.0224L17.795 60.6016L16.715 61.6344L15.8486 60.728L18.9494 57.7624L18.9486 57.7616ZM18.4406 59.9824L18.9054 60.468C19.1542 60.728 19.4038 60.7632 19.6206 60.556C19.8246 60.3608 19.8302 60.1064 19.5814 59.8464L19.1046 59.348L18.4414 59.9824H18.4406Z'
          fill='black'
        />
        <path
          d='M24.5334 62.7672L25.6038 63.4656L24.591 67.9272L23.4758 67.1992L23.6438 66.5568L22.4278 65.7632L21.9078 66.176L20.863 65.4944L24.5342 62.7672H24.5334ZM23.1358 65.1944L23.8798 65.68L24.1126 64.8288C24.1718 64.6168 24.2782 64.2776 24.2782 64.2776L24.2686 64.2712C24.2686 64.2712 24.0062 64.508 23.831 64.6448L23.1358 65.1944Z'
          fill='black'
        />
        <path
          d='M26.3151 66.4304C26.8295 65.2544 28.0199 64.7336 29.2015 65.2504C29.6303 65.4376 29.9671 65.716 30.1663 66.0392C30.4503 66.4976 30.4943 67.0408 30.3247 67.5624L29.1759 67.06C29.2831 66.74 29.1447 66.352 28.7711 66.1888C28.2271 65.9504 27.7615 66.2968 27.4799 66.94C27.1983 67.5832 27.3047 68.1792 27.8159 68.4032C28.2175 68.5784 28.5743 68.4208 28.7671 68.0856L29.9271 68.5928C29.7039 69.0128 29.3383 69.3104 28.9399 69.4576C28.4631 69.6352 27.9823 69.5952 27.4111 69.3448C26.2127 68.8208 25.8015 67.6056 26.3159 66.4296L26.3151 66.4304Z'
          fill='black'
        />
        <path
          d='M31.0968 68.4232C31.4008 67.176 32.484 66.4576 33.7368 66.7632C34.1912 66.8736 34.5712 67.0904 34.8232 67.3736C35.1824 67.776 35.3192 68.3032 35.2424 68.8464L34.024 68.5496C34.0744 68.216 33.8712 67.8576 33.4752 67.7616C32.8984 67.6208 32.4992 68.0424 32.3328 68.724C32.1664 69.4064 32.3736 69.9752 32.916 70.1072C33.3416 70.2112 33.6664 69.9936 33.7976 69.6304L35.028 69.9304C34.8808 70.3824 34.5712 70.7392 34.2048 70.952C33.7656 71.2096 33.2856 71.2528 32.6792 71.1048C31.4088 70.7952 30.7936 69.6696 31.0976 68.4216L31.0968 68.4232Z'
          fill='black'
        />
        <path
          d='M36.48 67.4064L39.812 67.6576L39.7352 68.6744L37.6528 68.5176L37.6088 69.0976L39.3496 69.2288L39.2784 70.168L37.5376 70.0368L37.4872 70.7072L39.6288 70.8688L39.548 71.94L36.156 71.684L36.4792 67.4064H36.48Z'
          fill='black'
        />
        <path
          d='M40.8311 70.5864L42.1111 70.4808C42.1815 70.8184 42.4575 70.9576 42.8703 70.924C43.1151 70.904 43.4063 70.7832 43.3855 70.5264C43.3639 70.2696 43.0719 70.2272 42.5343 70.1392C41.8015 70.0192 40.9143 69.8456 40.8391 68.9368C40.7583 67.956 41.5287 67.5192 42.4911 67.4392C43.3759 67.3656 44.2199 67.6696 44.3151 68.6008L43.0775 68.7032C43.0223 68.4728 42.8423 68.3376 42.5015 68.3656C42.2263 68.3888 42.0455 68.5296 42.0599 68.7032C42.0807 68.9544 42.4487 68.96 43.1007 69.0624C43.8327 69.1768 44.5735 69.3984 44.6487 70.3072C44.7255 71.24 43.9359 71.8048 42.9255 71.8888C41.8135 71.9808 40.9303 71.572 40.8311 70.5872V70.5864Z'
          fill='black'
        />
        <path
          d='M45.7984 70.0848L47.044 69.7728C47.168 70.0944 47.4632 70.1872 47.8648 70.0864C48.1032 70.0264 48.3712 69.8608 48.3088 69.6104C48.2464 69.36 47.9504 69.3664 47.4064 69.3664C46.664 69.3664 45.7608 69.3392 45.5392 68.4552C45.3 67.5008 45.9896 66.9448 46.9264 66.7096C47.788 66.4936 48.6696 66.6568 48.9144 67.56L47.7096 67.8616C47.6176 67.6432 47.4184 67.5384 47.0864 67.6216C46.8184 67.6888 46.6632 67.8576 46.7056 68.0264C46.7672 68.2712 47.1304 68.2168 47.7912 68.212C48.532 68.2056 49.2992 68.3048 49.52 69.1896C49.7472 70.0976 49.0592 70.7832 48.076 71.0296C46.9936 71.3008 46.056 71.0408 45.7984 70.0848Z'
          fill='black'
        />
        <path d='M49.6494 65.968L50.8182 65.5144L52.3694 69.5144L51.2006 69.968L49.6494 65.968Z' fill='black' />
        <path
          d='M51.8303 65.128L53.7815 64.0688C54.1503 63.868 54.4735 63.8088 54.7567 63.8528C55.0775 63.9032 55.3503 64.0904 55.5191 64.4016C55.7135 64.76 55.6839 65.0696 55.4423 65.3784L55.4511 65.3944C55.9343 65.3032 56.3599 65.5224 56.5919 65.9488C56.8127 66.3552 56.8151 66.7496 56.6495 67.0848C56.5087 67.3656 56.2559 67.6056 55.8663 67.8176L53.8783 68.8968L51.8311 65.1272L51.8303 65.128ZM54.3927 65.6344C54.6039 65.52 54.6919 65.3424 54.5831 65.1424C54.4775 64.9472 54.2663 64.9112 54.0559 65.0264L53.3759 65.396L53.7079 66.0072L54.3935 65.6352L54.3927 65.6344ZM55.2743 67.0192C55.5119 66.8904 55.5975 66.6592 55.4743 66.4328C55.3423 66.1904 55.0975 66.1664 54.8711 66.2896L54.0911 66.7136L54.4887 67.4464L55.2743 67.02V67.0192Z'
          fill='black'
        />
        <path d='M55.8696 62.7712L56.8872 62.0384L59.3936 65.52L58.376 66.2528L55.8696 62.7712Z' fill='black' />
        <path
          d='M57.7742 61.38L58.703 60.5376L60.8638 62.92L62.2238 61.6864L62.9454 62.4816L60.6566 64.5584L57.7734 61.3808L57.7742 61.38Z'
          fill='black'
        />
        <path d='M60.6672 58.6072L61.4936 57.664L64.72 60.492L63.8936 61.4352L60.6672 58.6072Z' fill='black' />
        <path
          d='M62.1168 56.9816L64.1928 53.9232L65.0768 54.5232L64.3856 55.5408L67.0512 57.3504L66.3464 58.388L63.6808 56.5784L63 57.5816L62.116 56.9816H62.1168Z'
          fill='black'
        />
        <path
          d='M67.6847 53.1672L64.5415 53.3472L65.1071 52.108L66.1639 52.0896C66.5231 52.0824 66.9111 52.1144 66.9111 52.1144L66.9159 52.1032C66.9159 52.1032 66.6407 51.8256 66.4103 51.5488L65.7319 50.7376L66.2855 49.5264L68.2047 52.0256L69.5743 52.6512L69.0535 53.792L67.6839 53.1664L67.6847 53.1672Z'
          fill='black'
        />
      </g>
      <defs>
        <clipPath id='clip0_17462_1771'>
          <rect width='80' height='80' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}

export default AllyantLogo
