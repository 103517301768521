import React, { useEffect, useState } from 'react'
import Link from 'next/link'
import Router from 'next/router'
import { observer } from 'mobx-react-lite'

import MediaQuery from 'components/MediaQuery'
import SiteNavigationMobile from 'components/SiteNavigationMobile'
import AvatarIcon from 'icons/Avatar'
import HamburgerMenu from 'icons/HamburgerMenu'
import Close from 'icons/Close'

import { siteHeaderEvents } from 'lib/ga'
import THEME from 'utils/theme'
import { useStore } from 'hooks'
import { AuthStore } from 'stores/auth'
import { EventsStore } from 'stores/events'

import SiteNavigation from 'components/SiteNavigation'

const SiteHeader = ({ minimalHeader, showNavBarCTA, useAvailableEventLogo = true }) => {
  const authStore = useStore(AuthStore)
  const eventsStore = useStore(EventsStore)

  const [mobileNavOpen, setIsMobileNavOpen] = useState(false)
  const toggleMobileNavOpen = () => {
    setIsMobileNavOpen((prev) => !prev)
  }

  useEffect(() => {
    const onRouteChangeStart = () => setIsMobileNavOpen(false)
    Router.events.on('routeChangeStart', onRouteChangeStart)
    return () => {
      Router.events.off('routeChangeStart', onRouteChangeStart)
    }
  }, [])

  const eventNavItems = eventsStore.getNavItems()
  const event = eventsStore.current
  const eventLogo = eventsStore?.current?.metadata?.logo_url ?? '/static/images/presentedByLogo.png'
  const logoUrl = useAvailableEventLogo ? eventLogo : '/static/images/presentedByLogo.png'

  return (
    <header className={`siteHeader ${minimalHeader ? 'siteHeader--minimal' : ''}`}>
      <div className='container'>
        <Link href='/' legacyBehavior>
          <a
            className='siteHeader__logo'
            onClick={() => {
              siteHeaderEvents.logo()
            }}
          >
            <img src={logoUrl} alt='Rough N Rowdy' />
          </a>
        </Link>
        <div className='siteHeader__right'>
          {!minimalHeader && (
            <>
              <MediaQuery minWidth={THEME.BREAKPOINTS.SM}>
                <SiteNavigation eventNavItems={eventNavItems} />
              </MediaQuery>
              {!process.env.WINTERIZED && (
                <>
                  <Link href={authStore.loggedIn ? '/account' : '/account/signin'} legacyBehavior>
                    <a
                      className='iconLink'
                      onClick={() => {
                        if (authStore.loggedIn) siteHeaderEvents.account()
                      }}
                    >
                      <MediaQuery minWidth={THEME.BREAKPOINTS.SM}>
                        <AvatarIcon color='white' width='auto' height='20px' />
                        <span className='iconSubtitle'>{authStore.loggedIn ? 'Account' : 'Sign In'}</span>
                      </MediaQuery>
                    </a>
                  </Link>
                </>
              )}
              {showNavBarCTA && event?.metadata.nav_bar_cta?.active && (
                <Link href={event.metadata.nav_bar_cta.destination} legacyBehavior>
                  <button className='btn btn-primary nav-cta'>{event.metadata.nav_bar_cta.label}</button>
                </Link>
              )}
              <MediaQuery maxWidth={THEME.BREAKPOINTS.SM - 1}>
                <a className='iconLink iconLink--menu' onClick={toggleMobileNavOpen}>
                  {mobileNavOpen ? <Close color='white' /> : <HamburgerMenu />}
                </a>
              </MediaQuery>
            </>
          )}
        </div>
      </div>

      <MediaQuery maxWidth={THEME.BREAKPOINTS.SM - 1}>
        <SiteNavigationMobile
          eventNavItems={eventNavItems}
          isOpen={mobileNavOpen}
          toggleMobileNavOpen={toggleMobileNavOpen}
        />
      </MediaQuery>

      <style jsx>{`
        .siteHeader {
          background: #000000;
          position: relative;
          padding: 0;
        }
        .siteHeader > .container {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 8px 16px;
        }
        .siteHeader--minimal > .container {
          justify-content: center;
        }
        .siteHeader__logo {
          display: block;
          margin-right: 16px;
        }
        .siteHeader__logo img {
          width: auto;
          height: 30px;
        }
        .siteHeader__right {
          display: flex;
          align-items: center;
        }
        .iconLink {
          text-align: center;
          color: white;
          margin-left: 15px;
          display: block;
        }
        .iconSubtitle {
          width: auto;
        }
        .iconLink--menu {
          margin-left: 15px;
        }
        .iconLink span {
          display: block;
          font-size: 10px;
          font-weight: 500;
          line-height: 12px;
        }
        .siteHeader__navContainerBottom {
          margin: 9px 0 0;
        }
        .nav-cta {
          color: white;
          outline: none;
          background: ${THEME.COLORS.PRIMARY};
          font-family: ${THEME.FONTS.HELVETICA};
          font-size: 10px;
          text-transform: uppercase;
          margin: 0;
          padding: 8px 6px;
          line-height: 1.25;
        }
        @media (min-width: ${THEME.BREAKPOINTS.SM}px) {
          .siteHeader__navContainerBottom {
            margin: 4px 0 0;
          }
          .siteHeader__navContainer {
            margin: 0 auto;
            margin-top: 5px !important;
          }
          .siteHeader__navContainerBottom {
            display: none;
          }
          .siteHeader > .container {
            margin: auto;
            padding-top: 10px;
            padding-bottom: 0px;
          }
          .iconLink {
            margin-left: 16px;
          }
          .nav-cta {
            margin: 0 0 0 1.75rem;
            padding: 8px 10px;
          }
        }
        @media (min-width: ${THEME.BREAKPOINTS.MD}px) {
          .siteHeader__logo img {
            height: 50px;
            width: auto;
            max-width: none;
          }
          .nav-cta {
            font-size: 14px;
            padding: 8px 16px;
          }
        }
      `}</style>
    </header>
  )
}

export default observer(SiteHeader)
